@import "../../styles/font.scss";

footer {
    min-height: 557px;
    background-color: #ebebf8;
    padding: 50px 0px;

    img {
        width: 100%;
        max-width: 335px;
        padding: 5px;
        margin-bottom: 30px;
    }

    .is-three-fifths {
        @media only screen and (max-width: 1420px) {
            width: 100% !important;
        }
    }

    .columnsInfos {
        width: 100%;
        margin-top: 10px;

        .is-full {
            @media only screen and (max-width: 768px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &__contatos {
            h3 {
                color: #000000;
                margin-bottom: 40px;
            }

            span {
                display: block;
                color: #8a8a8a;
                font-size: 1.5rem;
                font-weight: 500;
                margin-bottom: 5px;

                @media only screen and (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    span {
        color: #677294;
        font-size: 1rem;
    }

    .redeSocial {
        background-color: #c4c4c4;
        color: white;
        width: 78px;
        height: 78px;
        margin: 0px 5px;
        display: inline-block;
        border-radius: 100px;

        font-size: 2.5rem;
        transition: 0.3s;

        @media only screen and (max-width: 1420px) {
            width: 50px;
            height: 50px;
            font-size: 2rem;
        }

        &:hover {
            background-color: #111d4d;
            opacity: 1;
        }
    }
}

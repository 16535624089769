@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primary-font: "Roboto", sans-serif;
$secundary-font: "Poppins", sans-serif;

h1 {
    font-family: $secundary-font;
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 500;
}

h2 {
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 700;
}

h3 {
    font-family: $secundary-font;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
}

p {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem;
}

a {
    font-size: 1.37rem;
    font-style: normal;
    font-weight: 400;

    &:hover {
        opacity: 0.5;
    }
}

span {
    font-family: $secundary-font;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

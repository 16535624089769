.App {
    &__servicos {
        width: 100%;
        text-align: center;
        padding: 100px 0px;

        h1 {
            color: #000000;

            @media only screen and (max-width: 1024px) {
                font-size: 3rem;
                line-height: 55px;
            }
        }

        h2 {
            position: relative;
            color: #000000;
            display: inline-block;
            margin-top: 100px;
            line-height: 60px;

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 120%;
                height: 65%;
                background-color: #ff8d30;
                z-index: -1;
            }

            @media only screen and (max-width: 1024px) {
                font-size: 3rem;
                margin-top: 40px;
            }
        }

        p {
            font-size: 1.88rem;
            color: #707070;
            margin-top: 30px;

            @media only screen and (max-width: 1024px) {
                font-size: 1.6rem;
            }
        }

        &--imgs {
            width: 100%;
            margin-top: 35px;

            &--content {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &--item {
                    position: relative;
                    box-sizing: border-box;
                    width: 32%;

                    .fade {
                        position: absolute;
                        width: 100%;
                        max-width: 511px;
                        height: 100%;
                        background-color: #1d317f8f;
                        opacity: 0;
                        transition: 0.3s;

                        display: flex;
                        align-content: center;
                        justify-content: center;
                        flex-wrap: wrap;

                        h3 {
                            font-size: 2.2rem;
                            font-weight: 500;
                            color: #ff8d30;

                            @media only screen and (max-width: 769px) {
                                font-size: 1.5rem;
                            }

                            @media only screen and (max-width: 580px) {
                                font-size: 2rem;
                            }
                        }

                        &:hover {
                            opacity: 1;
                        }

                        @media only screen and (max-width: 769px) {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                        max-width: 511px;
                        display: block;
                    }

                    @media only screen and (max-width: 580px) {
                        width: 100%;
                        justify-content: center !important;
                        margin-top: 20px;
                    }
                }
            }
        }

        @media only screen and (max-width: 769px) {
            padding: 40px 0px;
        }
    }

    &__quemSomos {
        padding: 100px 0px;
        position: relative;

        &--detalheEsquerdo {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            z-index: -1;
            width: 35%;
            max-width: 700px;

            @media only screen and (max-width: 769px) {
                display: none;
            }
        }

        .columns {
            @media only screen and (max-width: 769px) {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column-reverse;
            }
        }

        &--text {
            width: 90%;
            max-width: 810px;
            margin: 0 auto;

            h1 {
                position: relative;
                font-size: 3rem;
                font-weight: 600;
                color: #000000;
                margin-bottom: 50px;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -12px;
                    left: 0px;
                    width: 131px;
                    height: 12px;
                    background-color: #ff8d30;
                }
            }

            p {
                color: #707070;

                @media only screen and (max-width: 1024px) {
                    font-size: 1.6rem;
                }
            }
        }

        @media only screen and (max-width: 769px) {
            padding: 40px 0px;
        }
    }

    &__projetos {
        width: 100%;
        text-align: center;
        padding-bottom: 200px;
        position: relative;

        &--detalheDireito {
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translateY(-50%);
            z-index: -1;
            width: 30%;
            max-width: 466px;
            margin-top: 15px;

            @media only screen and (max-width: 769px) {
                display: none;
            }
        }

        h2 {
            position: relative;
            color: #000000;
            display: inline-block;
            margin-top: 100px;
            line-height: 60px;

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 120%;
                height: 65%;
                background-color: #ff8d30;
                z-index: -1;
            }

            @media only screen and (max-width: 1024px) {
                font-size: 3rem;
            }
        }

        p {
            font-size: 1.8rem;
            color: #707070;
            margin-top: 30px;

            @media only screen and (max-width: 1024px) {
                font-size: 1.6rem;
            }
        }

        &--videos {
            width: 100%;
            margin-top: 50px;

            &--content {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &--item {
                    position: relative;
                    box-sizing: border-box;
                    width: 32%;

                    @media only screen and (max-width: 1024px) {
                        width: 100%;
                        justify-content: center !important;
                        margin-top: 20px;
                    }
                }
            }
        }

        @media only screen and (max-width: 769px) {
            padding-bottom: 60px;
        }
    }
}

.banner {
    &__swiper {
        .swiper-wrapper {
            height: calc(100vh - 134px);
        }

        &--text {
            position: absolute;
            width: 90%;
            z-index: 10;
            opacity: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            h2 {
                font-size: 4.5rem;
                font-weight: 700;
                color: #ee771c;
                line-height: 78px;

                @media only screen and (max-width: 520px) {
                    font-size: 3rem;
                    line-height: 55px;
                }
            }

            p {
                font-size: 3.12rem;
                font-weight: 300;
                line-height: 50px;
                color: #fefefe;

                @media only screen and (max-width: 520px) {
                    font-size: 2.5rem;
                    line-height: 45px;
                }
            }
        }

        &--img {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: "";
                background: #1d307f 0% 0% no-repeat padding-box;
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                opacity: 0.61;
            }
        }

        .swiper-pagination {
            bottom: 30px !important;
        }

        .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 26px;
                height: 26px;
                background-color: white;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background-color: #ff8d30;
            }
        }
    }
}
